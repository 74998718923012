@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

// components
@import '../components';

@import './site/index';

//aos
@import '~aos/src/sass/aos';

@import './youtubeNew.scss';

@media print {
  @import './print';
}

//banner-text-bar
section.banner-text-bar {
  .htmlPanel {
    p {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.choose-file-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  label.choose-file-label {
    background-color: white;
    margin-bottom: 0px !important;
    //font-family: $family-button;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 600;
    //color: #444;
  }
  div.file__attach__text {
    margin-left: 1rem;
    //color: white;
  }
}

.youtubePlayer--standard, .youtubePlayer--full {
  @media screen and (max-width: 400px) {
    height: 50vh !important;
    //padding-top: 56.25%;
    // iframe.youtubePlayer__iframe {
    //   width: 100% !important;
    //   height: 100% !important;
    //   top: 0px !important;
    //   left: 0px !important;
    // }
  }
}